<template>
	<div id="storeEdit" class="el-content">
		<a-form :label-col="{ span: 3 }" :wrapper-col="{ span: 12 }">
			<a-form-item label="店铺名称">
				<a-input v-model:value="form.name"></a-input>
			</a-form-item>
			<a-form-item label="昵称" v-if="form.user">
				<a-input v-model:value="form.user.nickname" disabled></a-input>
			</a-form-item>
			<a-form-item label="联系电话">
				<a-input v-model:value="form.mobile"></a-input>
			</a-form-item>
			<a-form-item label="商户LOGO">
				<kd-img-select :mutil="false" :img="form.logo" @emitUrls="(url)=>{ form.logo = url }"></kd-img-select>
			</a-form-item>
			<a-form-item label="图片展示">
				<a-image v-for="(val,index) in form.images" :key="index" style="width: 140px; height: 100px" :src="val" />
			</a-form-item>
			<a-form-item label="店铺介绍">
				<a-textarea v-model:value="form.description" placeholder="店铺介绍" :rows="4" />
			</a-form-item>
			<a-form-item label="入驻状态">
				<a-radio-group v-model:value="form.apply_status">
					<a-radio :value="1">申请中</a-radio>
					<a-radio :value="2">驳回申请</a-radio>
					<a-radio :value="3">允许入驻</a-radio>
				</a-radio-group>
			</a-form-item>
			<a-form-item label="驳回理由" v-if="form.apply_status == 2">
				<a-textarea v-model:value="form.reason" placeholder="驳回理由" :rows="4" />
			</a-form-item>
			<a-form-item :wrapper-col="{ offset: 3 }">
				<a-space>
					<a-button type="primary" @click="submitSave">保存</a-button>
					<a-button @click="$router.go(-1)">返回</a-button>
				</a-space>
			</a-form-item>
		</a-form>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import shopModel from '@/api/addons/shop'
export default {
	setup(){
		const _d = reactive({
			form:{
				id:0,
				apply_status:1,
				reason:'',
				user:{nickname:''},
				mobile:"",
				images:[],
				name:"",
				logo:"",
				description:""
			},
		})
		const opt = useRoute().query
		if( opt.id ) shopModel.getShopApplyDetail(opt.id,res=>{
			_d.form = res
			_d.form.apply_status = res.apply_status
			_d.form.id = opt.id
		})
		const submitSave = ()=>shopModel.auditShopApply(_d.form)
		return{
			...toRefs(_d),
			submitSave
		}
	}
}
</script>

<style>
</style>
